import React from 'react';
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import NewsArticleBody from '../../PC/organisms/NewsArticleBody';
import SEO from '../../seo';
import MobileNewsArticleBody from '../../mobile/organisms/MobileNewsArticleBody';

const Root = styled.div`
    display: inline-block;
    width: 100%;
`

const NewsArticleTemplate = ({ post }) => {

    const breakPoints = useBreakpoint()

    return (
        <Root>
            <SEO
                lang="jp"
                title={post.title}
            />
            {breakPoints.pc
                ?
                    <NewsArticleBody
                        title={post.title}
                        thumbnailPath={post.thumbnailPath}
                        date={post.date}
                        tags={post.tags}
                        body={post.body}
                    />
                :
                    null
            }
            {breakPoints.mobile
                ?
                    <MobileNewsArticleBody
                        title={post.title}
                        thumbnailPath={post.thumbnailPath}
                        date={post.date}
                        tags={post.tags}
                        body={post.body}
                    />
                :
                    null
            }
        </Root>
    )
}

export default NewsArticleTemplate
