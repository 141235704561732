import React from 'react';
import styled from "styled-components"
import { MobileDoubleLabelButton } from '../atoms/MobileButton';
import MobileHeaderInner from '../molecules/MobileHeaderInner';

const Root = styled.div`
    display: inline-block;
    width: 100%;
`

const ThumbnailImg = styled.img`
    width: 100%;
`

const BodyOuter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const BodyInner = styled.div`
    display: inline-block;
    width: 85%;
    min-width: 280px;
    padding: 40px 0px;
    word-break: break-all;
`

const Date = styled.p`
    width: calc( 100% - 40px );
    padding: 10px 0px 10px 20px ;
    font-weight: 500;
`

const Title = styled.h1`
    display: inline-block;
    width: 100%;
    margin: 30px 0px;
    font-size: 32px;
    line-height: 36px;
`

const BodyContents = styled.div`
    width: 100%;
    padding: 50px 0px;
    line-height: 28px;
    img {
        width: 100%;
    }
`

const MobileNewsArticleBody = ({
    title,
    thumbnailPath,
    date,
    tags,
    body
}) => {

    return (
        <Root>
            <BodyOuter>
                <BodyInner>
                    <Date>{date}</Date>
                    <Title>{title}</Title>
                    <ThumbnailImg src={thumbnailPath} loading='lazy' />
                    <BodyContents dangerouslySetInnerHTML={{ __html: body}} />
                    <MobileDoubleLabelButton
                        jpLabel='ニュース一覧に戻る'
                        enLabel='News'
                        href='/news'
                    />
                </BodyInner>
            </BodyOuter>
        </Root>
    )
}

export default MobileNewsArticleBody
