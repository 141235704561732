import React from 'react';
import '../layouts/style.css'
import { graphql } from 'gatsby';
import NewsArticleTemplate from '../components/templates/PostViewTemplates/NewsArticleTemplate';
import ServiceProductTemplate from '../components/templates/PostViewTemplates/ServiceProductTemplate';

const PostView = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter
  const postData = {
    title: frontmatter.title,
    thumbnailPath: frontmatter.thumbnail,
    date: frontmatter.date,
    tags: frontmatter.tags,
    body: data.markdownRemark.html,
  }
  if (postData.date === null) {
    return (
      <ServiceProductTemplate post={postData} />
    )
  } else {
    return (
      <NewsArticleTemplate post={postData} />
    )
  }
}

export const query = graphql`
  query($slug: String) {
    markdownRemark( fields: { slug: { eq: $slug }}) {
      html
      frontmatter {
        title
        thumbnail
        date
        tags
        slug
      }
    }
  }
`

export default PostView
