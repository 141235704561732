import React from 'react';
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import ServiceProductBody from '../../PC/organisms/ServiceProductBody';
import SEO from '../../seo';
import MobileServiceProductBody from '../../mobile/organisms/MobileServiceProductBody';

const Root = styled.div`
    display: inline-block;
    width: 100%;
`


const ServiceProductTemplate = ({ post }) => {

    const breakPoints = useBreakpoint()

    return (
        <Root>
            <SEO
                lang="jp"
                title={post.title}
            />
            {breakPoints.pc
                ?
                    <ServiceProductBody
                        title={post.title}
                        thumbnailPath={post.thumbnailPath}
                        tags={post.tags}
                        body={post.body}
                    />
                :
                    null
            }
            {breakPoints.mobile
                ?
                    <MobileServiceProductBody
                        title={post.title}
                        thumbnailPath={post.thumbnailPath}
                        tags={post.tags}
                        body={post.body}
                    />
                :
                    null
            }
        </Root>
    )
}

export default ServiceProductTemplate
