import React from 'react';
import styled from "styled-components"
import { HorizontalLine } from '../../PC/atoms/Decoration';
import HeaderInner from '../../PC/molecules/HeaderInner';
import { DoubleLabelButton } from '../atoms/Button';

const Root = styled.div`
    display: inline-block;
    width: 100%;
`

const HeaderOuter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
`

const ThumbnailImg = styled.img`
    width: 100%;
`

const BodyOuter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const BodyInner = styled.div`
    display: inline-block;
    width: 900px;
    padding: 120px 0px;
`

const Date = styled.p`
    width: calc( 100% - 40px );
    padding: 20px 0px 20px 40px ;
    font-weight: 500;
`

const Title = styled.h1`
    display: inline-block;
    width: 100%;
    margin: 40px 0px;
`

const BodyContents = styled.div`
    width: 100%;
    padding: 80px 0px;
    line-height: 28px;
    img {
        width: 100%;
    }
`

const NewsArticleBody = ({
    title,
    thumbnailPath,
    date,
    tags,
    body
}) => {

    return (
        <Root>
            <HeaderOuter>
                <HeaderInner color='black' />
            </HeaderOuter>
            <BodyOuter>
                <BodyInner>
                    <Date>{date}</Date>
                    <HorizontalLine />
                    <Title>{title}</Title>
                    <ThumbnailImg src={thumbnailPath} loading='lazy' />
                    <BodyContents dangerouslySetInnerHTML={{ __html: body}} />
                    <DoubleLabelButton
                        jpLabel='ニュース一覧に戻る'
                        enLabel='News'
                        href='/news'
                    />
                </BodyInner>
            </BodyOuter>
        </Root>
    )
}

export default NewsArticleBody
