import React from 'react';
import styled from "styled-components"
import { MobileDoubleLabelButton } from '../atoms/MobileButton';
import MobileTagLabel from '../atoms/MobileTagLabel';
import MobileHeader from './MobileHeader';

const Root = styled.div`
    display: inline-block;
    width: 100%;
`

const ThumbnailImg = styled.img`
    width: 100%;
`

const BodyOuter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const BodyInner = styled.div`
    display: inline-block;
    width: 85%;
    min-width: 280px;
    padding: 40px 0px;
    word-break: break-all;
`

const Title = styled.h1`
    display: inline-block;
    width: 100%;
    margin: 30px 0px;
    font-size: 32px;
    line-height: 36px;
`

const Category = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const CategoryTitle = styled.p`
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin: 16px 0px;
    font-weight: 500;
`

const Categories = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const BodyContents = styled.div`
    width: 100%;
    padding: 50px 0px;
    line-height: 28px;
    img {
        width: 100%;
    }
`

const MobileServiceProductBody = ({
    title,
    thumbnailPath,
    tags,
    body
}) => {

    return (
        <Root>
            <BodyOuter>
                <BodyInner>
                    <ThumbnailImg src={thumbnailPath} loading='lazy' />
                    <Title>{title}</Title>
                    <Category>
                        <CategoryTitle>Categories</CategoryTitle>
                        <Categories>
                            {tags.map((tag, index) => (
                                <MobileTagLabel tag={tag} key={index} />
                            ))}
                        </Categories>
                    </Category>
                    <BodyContents dangerouslySetInnerHTML={{ __html: body}} />
                    <MobileDoubleLabelButton
                        jpLabel='サービス一覧に戻る'
                        enLabel='Service'
                        href='/service'
                    />
                </BodyInner>
            </BodyOuter>
        </Root>
    )
}

export default MobileServiceProductBody
